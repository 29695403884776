import moment from "moment"
import React from "react"
import { MdCategory } from "react-icons/md"
import { FaMoneyBillAlt } from "react-icons/fa"
import { IoPeopleSharp } from "react-icons/io5"
import { GiBackwardTime } from "react-icons/gi"
import { BsGenderAmbiguous } from "react-icons/bs"
import { useMemo } from "react"
import { WEBAPP_ROUTE_ACTIONS, getWebappRouteActionURL } from "../utils/request"

export default function ScholarshipCard(props) {
  const SOCIAL_CATEGORY = {
    GENERAL: "0",
    SC: "1",
    ST: "2",
    OBC: "3",
    DIFFERENTLY_ABLED: "4",
    ORHPAN: "5",
  }
  const SOCIAL_CATEGORY_NAMES = {
    [SOCIAL_CATEGORY.GENERAL]: "General",
    [SOCIAL_CATEGORY.SC]: "SC",
    [SOCIAL_CATEGORY.ST]: "ST",
    [SOCIAL_CATEGORY.OBC]: "OBC",
    [SOCIAL_CATEGORY.DIFFERENTLY_ABLED]: "Differently Abled",
    [SOCIAL_CATEGORY.ORHPAN]: "Orphan",
  }

  const social_categories_by_name = useMemo(() => {
    const social_categories_by_name = []

    ;(props.social_categories || []).forEach(social_category_code => {
      const social_category_name = SOCIAL_CATEGORY_NAMES[social_category_code]
      social_categories_by_name.push(social_category_name)
    })

    return social_categories_by_name.join(", ") || "All categories"
  }, [props.social_categories])

  return (
    <>
      <div class="flex flex-col justify-between w-full px-3 py-4 my-3  mx-auto bg-gray-100 rounded-lg border-2">
        <div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row w-full justify-center">
              <div className="flex flex-col">
                <h1 className="text-xl font-semibold text-japanese_indigo mx-auto ">
                  {props.title}
                </h1>
              </div>
            </div>
          </div>
          <div className=" flex w-full justify-center">
            <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  my-4 gap-x-24 gap-y-2 ">
              <div className="flex flex-row mx-4">
                <MdCategory className="text-orange" size={20} />
                <p className="font-semibold text-sm text-japanese_indigo mx-2">
                  {social_categories_by_name}
                </p>
              </div>
              <div className="flex flex-row mx-5">
                <FaMoneyBillAlt className="text-orange" size={20} />
                <p className="font-semibold text-sm text-japanese_indigo mx-2">
                  {!props?.amount || props.amount == 0
                    ? "Not fixed"
                    : "₹" + props.amount}
                </p>
              </div>
              <div className="flex flex-row mx-5">
                <BsGenderAmbiguous className="text-orange" size={20} />
                <p className="font-semibold text-sm text-japanese_indigo mx-2">
                  {props.gender === 0
                    ? "Male Only"
                    : props.gender === 1
                    ? "Female Only"
                    : props.gender === 2
                    ? "Others"
                    : "All Genders"}
                </p>
              </div>
              <div className="flex flex-row mx-5">
                <IoPeopleSharp className="text-orange" size={20} />
                <p className="font-semibold text-sm text-japanese_indigo mx-2">
                  Age{" "}
                  {props.min_age !== null && props.max_age !== null
                    ? `${props.min_age} - ${props.max_age} Years`
                    : "Not Specified"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row justify-center">
          <button
            onClick={() => {
              window.open(
                getWebappRouteActionURL(
                  WEBAPP_ROUTE_ACTIONS.apply_for_scholarship,
                  { scholarshipId: props.id }
                ),
                "_blank"
              )
            }}
            className="mx-2 mb-2 rounded-md py-3 px-5 bg-orange text-white font-semibold"
          >
            Apply For Scholarship
          </button>
        </div>
      </div>
    </>
  )
}
