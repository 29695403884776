import React, { useContext, useState, Fragment, useEffect } from "react"
import Marquee from "react-fast-marquee"
import { Link, navigate } from "gatsby"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/solid"
import Partner1 from "../assets/image/partner1.png"
import Partner2 from "../assets/image/partner2.png"
import Partner3 from "../assets/image/partner3.png"
import Partner4 from "../assets/image/partner4.png"
import Partner5 from "../assets/image/partner5.png"
import Partner6 from "../assets/image/partner6.png"
import Partner7 from "../assets/image/partner7.png"
import Partner8 from "../assets/image/partner8.png"
import Partner9 from "../assets/image/partner9.png"
import Partner10 from "../assets/image/partner10.png"
import Partner11 from "../assets/image/partner11.png"
import Partner12 from "../assets/image/partner12.png"
import Partner13 from "../assets/image/partner13.png"
import Partner14 from "../assets/image/partner14.png"
import Partner15 from "../assets/image/partner15.png"
import Partner16 from "../assets/image/partner16.png"
import Partner17 from "../assets/image/partner17.png"
import Partner18 from "../assets/image/partner18.png"
import Partner20 from "../assets/image/partner20.png"
import Partner21 from "../assets/image/partner21.png"
import Circle from "../assets/image/home-circle.svg"
import EnglishStarLogo from "../assets/image/english-star-logo.jpg"
import Career from "../assets/image/career.png"
import Down from "../assets/image/down.svg"
import Search from "../assets/image/search.svg"
import Ruppee from "../assets/image/inr.svg"
import RuppeeGray from "../assets/image/inr-gray.svg"
import BottomNav from "../components/bottomNav"
import "../data.json"
import Layout from "../components/layout"
import innerText from "react-innertext"
import NewImageSlider from "../components/newImageSlider"
import { StateContext } from "../context/stateProvider"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"
import StoryCard from "../components/StoryCard"
import ScholarshipCard from "../components/ScholarshipCard"
import {
  webappHost,
  englishStarCourseId,
  YOUR_API_KEY,
  pythonBackendHost,
} from "../../config"
import { NavigationContext } from "../context/Navigation"
import userSvc from "../services/User"
import InstructorSvc from "../services/Instructor"
import { CourseContext } from "../context/Course"
import CourseCard from "../components/CourseCard"
import courseSvc from "../services/Course"
import _ from "lodash"
import Loader from "../components/Loader"
import loadScript from "load-script"

const { coursesByField } = require("../data.json")

const IndexPage = ({ pageContext }) => {
  const [reportsStat, setReportsState] = useState()
  // fetch the results
  useEffect(async () => {
    const reportsStat = await fetch(`${pythonBackendHost}/get_asdm_stats`)
      .then(response => response.json())
      .then(data => {
        setReportsState(data.data)
        return data.data
      })
      .catch(error => {
        // Handle errors
        console.error(error)
      })
  }, [])

  const allCourses = {
    categoryName: "All",
    id: -1,
    numCourses: 0,
  }
  const { language, setCourseName, setCategory } = useContext(StateContext)
  const { setPath } = useContext(NavigationContext)
  const { fetchAllCoursesUsers } = useContext(CourseContext)

  const {
    usersCount,
    instructorsCount,
    coursesByCategories,
    sliders,
    stories,
    partner,
  } = require("../data.json")

  console.log(
    "data====",
    usersCount,
    instructorsCount,
    coursesByCategories,
    sliders,
    stories,
    partner
  )

  const [courses, setCourses] = useState(sortEnglishCourse(coursesByField))
  useEffect(() => {
    ;(async () => {
      const allCourses = await courseSvc
        .fetchAllCourses(partner ? partner.id : undefined)
        .catch(err => {
          return []
        })

      setCourses(sortEnglishCourse(allCourses))
    })()
  }, [])

  useEffect(async () => {
    const asdmLocationTrackingBatchId = 258

    try {
      const instructors = await courseSvc.fetchInstructorByBatchId(
        asdmLocationTrackingBatchId
      )
      const instructorIds = instructors.map(instructor => instructor.id)

      // const user_locations = await courseSvc.fetchUserLocation(
      //   instructorIds.filter(item => item == 59) //Done to stop tracking of two other vans
      // )

      // const locationsArray = user_locations.courses_user_location.map(
      //   location => ({
      //     lat: location.location_latitude,
      //     lng: location.location_longitude,
      //   })
      // )
      const locationsArray = [
        {
          lat: 26.354767,
          lng: 91.971189,
        },
      ]

      // loadScript(
      //   `https://maps.googleapis.com/maps/api/js?key=${YOUR_API_KEY}`,
      //   () => {
      //     // The Google Maps API has loaded
      //     const map = new window.google.maps.Map(
      //       document.getElementById("map"),
      //       {
      //         center: { lat: 0, lng: 0 },
      //         zoom: 0,
      //       }
      //     )

      //     window.STMap = map

      //     const invisibleMarker = document.createElement("div")
      //     invisibleMarker.className = "opacity-100"

      //     const bounds = new window.google.maps.LatLngBounds()

      //     locationsArray.forEach(location => {
      //       const marker = new window.google.maps.Marker({
      //         position: location,
      //         map: map,
      //         content: invisibleMarker,
      //       })

      //       bounds.extend(marker.getPosition())
      //     })

      //     map.fitBounds(bounds)

      //     if (locationsArray.length === 1) {
      //       setTimeout(() => {
      //         map.setZoom(9)
      //       }, 1000)
      //     }
      //   }
      // )

      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${YOUR_API_KEY}`,
        () => {
          const map = new window.google.maps.Map(
            document.getElementById("map"),
            {
              center: { lat: 0, lng: 0 },
              zoom: 0,
            }
          )

          window.STMap = map

          const bounds = new window.google.maps.LatLngBounds()

          locationsArray.forEach(location => {
            const customMarkerEl = document.createElement("div")
            customMarkerEl.className = "opacity-100"

            const customOverlay = new window.google.maps.OverlayView()

            customOverlay.onAdd = function () {
              const panes = this.getPanes()
              panes.overlayLayer.appendChild(customMarkerEl)
            }

            customOverlay.draw = function () {
              const position = new window.google.maps.LatLng(
                location.lat,
                location.lng
              )
              const projection = this.getProjection()
              const point = projection.fromLatLngToDivPixel(position)
              customMarkerEl.style.left = `${point.x}px`
              customMarkerEl.style.top = `${point.y}px`
            }

            customOverlay.setMap(map)

            bounds.extend(
              new window.google.maps.LatLng(location.lat, location.lng)
            )
          })

          map.fitBounds(bounds)

          if (locationsArray.length === 1) {
            setTimeout(() => {
              map.setZoom(9)
            }, 1000)
          }
        }
      )
    } catch (err) {
      console.log(err)
      throw err
    }
  }, [])

  const [coursesName, setCoursesName] = useState("")
  const [active, setActive] = useState(false)
  const [categories, setCategories] = useState(
    coursesByCategories && coursesByCategories[0]
  )
  const intialCategory = coursesByCategories && coursesByCategories[0]
  const [currentUsersCount, setUsersCount] = useState(usersCount)
  const [currentInstructorCount, setCurrentInstructorCount] =
    useState(instructorsCount)
  useEffect(() => {
    //Get Total Partners
    InstructorSvc.getTotalInstructors()
      .then(setCurrentInstructorCount)
      .catch(console.error)

    // Get total users
    userSvc.getTotalUsers().then(setUsersCount).catch(console.error)
    // Get users by courses
    fetchAllCoursesUsers()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    setCourseName(coursesName)
    setCategory(categories)
    categories.id == -1
      ? navigate(`/courses`)
      : navigate(`/courses/${categories.id}`)
  }

  const { key: pageKey, lang } = pageContext

  //function to get partner courses
  function getPartnerCourses(partner_id, courses) {
    var partner_courses = []
    if (partner_id !== undefined) {
      courses.forEach(course => {
        if (course.partners.length !== 0) {
          for (var i = 0; i < course.partners.length; i++) {
            if (course.partners[i].id === partner_id)
              partner_courses.push(course)
          }
        }
      })
    }
    return partner_courses
  }

  let partner_courses = []
  //old partner website handling
  //Hero Section Headline For Partner
  if (partner) {
    partner_courses = getPartnerCourses(partner.id, courses)
    var hero_text_array = partner?.hero_section_headline.trim().split("\n")
  }

  return (
    // <TinaProvider cms={cms}>
    <MultiLangBody _key={pageKey}>
      <Layout
        title="SkillsTrainer - World's Largest Vocational Skilling & Certification Portal"
        lang={lang}
      >
        <div className="mb-20">
          <BottomNav></BottomNav>
          <div className={`bg-light px-10 lg:px-0 `}>
            <div className={`flex flex-col xl:flex-row content mx-auto`}>
              <div className="width-home1">
                <div className={` xl:pr-0 py-20 xl:pt-60 relative `}>
                  <img src={Circle} className="absolute top-24 -left-20" />
                  {partner ? (
                    <div
                      className={`text-5xl xl:text-${
                        partner.hero_section_font_size
                          ? partner.hero_section_font_size
                          : "5"
                      }xl font-semibold mt-24 xl:my-0 md:space-y-3`}
                    >
                      {partner?.hero_section_headline
                        .trim()
                        .split("\n")
                        .map((heroTitleLine, index) => (
                          <p
                            className={`${
                              index === 0 ? "red-dark" : "blue-dark2"
                            } leading-[3rem] md:leading-10 xl:whitespace-nowrap`}
                          >
                            <MultiLangField name={`hero_Partner_Title${index}`}>
                              {heroTitleLine}
                            </MultiLangField>
                          </p>
                        ))}
                    </div>
                  ) : (
                    <div className="home-text mt-24 xl:my-0">
                      <p className="red-dark">
                        <MultiLangField name={"hero_Title1"}>
                          World's Largest
                        </MultiLangField>
                      </p>

                      <p className="blue-dark2 my-3">
                        <MultiLangField name={"hero_Title2"}>
                          Vocational Skilling
                        </MultiLangField>
                      </p>
                      <p className="blue-dark2">
                        <MultiLangField name={"hero_Title3"}>
                          & Certification Portal
                        </MultiLangField>
                      </p>
                    </div>
                  )}

                  <p className="blue-dark3 text-sm mt-8 mb-4">
                    <MultiLangField name={"hero_SubTitle"}>
                      Make your career & change your family's life!
                    </MultiLangField>
                  </p>

                  <div className="w-28 div-height bg-red-500"></div>

                  {!partner && (
                    <div className="flex item-center my-12 mt-10 text-lg">
                      <div>
                        {/* <p className="red-dark ">5,82,365</p>
                  <p className="blue-dark2 mt-2">{learners}</p> */}

                        <p className="red-dark ">{currentUsersCount}</p>
                        <p className="blue-dark2 ">
                          <MultiLangField name={"hero_Learners"}>
                            Learners
                          </MultiLangField>
                        </p>
                      </div>
                      <div className="mx-10">
                        {/* <p className="red-dark">80+</p>
                    <p className="blue-dark2 mt-2">{courses}</p> */}
                        <p className="red-dark">90+</p>
                        <p className="blue-dark2 ">
                          <MultiLangField name={"hero_Courses"}>
                            Courses
                          </MultiLangField>
                        </p>
                      </div>
                      <div>
                        {/* <p className="red-dark">200+</p>
                  <p className="blue-dark2 mt-2">{partners}</p> */}
                        <p className="red-dark">200+</p>
                        <p className="blue-dark2 ">
                          <MultiLangField name={"hero_Partners"}>
                            Partners
                          </MultiLangField>
                        </p>
                      </div>
                      <div className="ml-10">
                        <p className="red-dark">
                          {currentInstructorCount * 100}+
                        </p>
                        <p className="blue-dark2 ">
                          <MultiLangField name={"hero_Instructors"}>
                            Trainers
                          </MultiLangField>
                        </p>
                      </div>
                    </div>
                  )}

                  <div
                    className={`${
                      partner ? "mt-40" : ""
                    } w-full flex flex-col sm:flex-row items-center justify-between sm:bg-white sm:border-2 p-2 sm:p-1 sm:rounded-lg`}
                  >
                    <div className="flex items-center w-full home-input">
                      <img src={Search} className="ml-1 h-4" />

                      <input
                        name="courseName"
                        type="text"
                        className="w-full ml-2 sm:border-r py-1 sm:border-gray-600 outline-none mr-3 text-sm"
                        // used react-innerText module to get innertext
                        placeholder={innerText(
                          <MultiLangField name={"Searchbar"}>
                            {categories === intialCategory
                              ? "What do you want to learn"
                              : ""}
                          </MultiLangField>
                        )}
                        onChange={e => setCoursesName(e.target.value)}
                      />
                    </div>
                    <div className="flex items-center mt-10 sm:mt-0 w-full sm:w-auto">
                      <div className="w-full z-10">
                        {/* <Listbox value={selected} onChange={setSelected}> */}
                        <Listbox value={categories} onChange={setCategories}>
                          <div className="relative mt-1">
                            <Listbox.Button className="rounded-lg relative w-full px-3 py-4 sm:py-3 pl-1 pr-10 text-left bg-white cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                              <span className="block truncate text-sm blue-dark1">
                                {/* {selected?.categoryName} */}
                                {categories?.categoryName}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <img src={Down} className="h-6" />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Listbox.Options className="absolute left-0 sm:-left-16 select-width py-1 mt-1 overflow-auto scroll text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {[allCourses]
                                  .concat(coursesByCategories)
                                  ?.map(category => {
                                    const { categoryName, id } = category

                                    return (
                                      <Listbox.Option
                                        // key={person.id}
                                        key={id}
                                        className={({ active }) =>
                                          `${
                                            active
                                              ? "text-yellow-900 bg-yellow-100"
                                              : "text-gray-900"
                                          }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                        }
                                        value={category}
                                        // value={person?.categoryName}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={`${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              } block truncate text-md pl-4`}
                                            >
                                              {categoryName}
                                              {/* {person.categoryName} */}
                                            </span>

                                            {selected ? (
                                              <span
                                                className={`${
                                                  active
                                                    ? "text-yellow-600"
                                                    : "text-yellow-600"
                                                }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                              >
                                                <CheckIcon
                                                  className="w-8 h-8"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    )
                                  })}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <button
                        // type="submit"
                        className="bg-orange text-white font-semibold rounded-lg p-3 ml-5 sm:ml-0"
                        onClick={handleSubmit}
                      >
                        <MultiLangField name={"SearchButton"}>
                          Search
                        </MultiLangField>
                      </button>
                    </div>
                  </div>
                  {/* <div className="my-6 max-w-max mx-auto">
                    <button className="button btn-blue">Search</button>
                  </div> */}
                </div>
              </div>
              {partner ? (
                partner.id === "51" ? (
                  <div className="width-home2 h-full xl:block relative lg:pl-20">
                    <div className="container my-16 px-4 mx-auto">
                      <div className="mb-16 text-gray-800 text-center">
                        <div
                          className="grid grid-cols-2  items-center"
                          style={{ marginTop: "17%" }}
                        >
                          <div className="">
                            <img
                              src={partner?.logo}
                              style={{ width: "150px" }}
                            />
                          </div>
                          <div className=" ">
                            <h2 className="text-3xl font-bold display-6 text-orange">
                              <h1>Skill Yatra 2023-24</h1>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="map"
                      style={{ height: "200px", width: "100%" }}
                    ></div>
                    <div className="container mt-16 px-4 mx-auto">
                      <div
                        id="stats-block"
                        className="mb-8 text-gray-800 text-center"
                      >
                        <div className="grid grid-cols-2 lg:grid-cols-2 gap-x-10 gap-y-4">
                          <div className=" ">
                            <h2 className="text-3xl font-bold display-6 text-orange">
                              {reportsStat?.total_schools}
                            </h2>
                            <h5 className="text-sm py-2 font-medium ">
                              Skill Yatra Schools
                            </h5>
                          </div>
                          <div className="">
                            <h2 className="text-3xl font-bold display-6">
                              <span className=" text-blue-500">
                                {
                                  reportsStat?.skill_yatra_tests_taken_in_schools
                                }
                              </span>
                            </h2>
                            <h5 className="text-sm py-2 font-medium ">
                              Skill Yatra Tests Taken in Schools
                            </h5>
                          </div>
                          {/* <div className="">
                            <h2 className="text-3xl font-bold display-6">
                              <span className=" text-orange">
                                {
                                  reportsStat?.skill_yatra_tests_taken_outside_schools
                                }
                              </span>
                            </h2>
                            <h5 className="text-sm py-2 font-medium">
                              Skill Yatra Tests Taken outside Schools
                            </h5>
                          </div> */}
                          <div className="">
                            <h2 className="text-3xl font-bold display-6 text-blue-500 ">
                              {reportsStat ? Number(13777) : ""}
                            </h2>
                            <h5 className="text-sm py-2 font-medium ">
                              Students Counselled
                            </h5>
                          </div>
                          <div className="">
                            <h2 className="text-3xl font-bold display-6">
                              <span className=" text-orange">
                                {reportsStat?.students_enroled_in_schools}
                              </span>
                            </h2>
                            <h5 className="text-sm py-2 font-medium ">
                              Students enrolled in Schools
                            </h5>
                          </div>
                          {/* <div className="">
                            <h2 className="text-3xl font-bold display-6">
                              <span className=" text-blue-500">
                                {reportsStat?.students_enroled_outside_schools}
                              </span>
                            </h2>
                            <h5 className="text-sm py-2 font-medium ">
                              Students enrolled outside Schools
                            </h5>
                          </div> */}
                        </div>
                      </div>
                      <div
                        id="district-block"
                        className="mb-8 border-4 bg-gray-200 p-4 rounded-xl flex justify-between"
                      >
                        <div className="mx-auto flex items-center justify-center flex-col">
                          <h2 className="text-3xl font-bold display-6">
                            <span className=" text-orange">
                              {reportsStat?.total_districts}
                            </span>
                          </h2>
                          <h5 className="py-2 text-lg font-semibold">
                            Districts
                          </h5>
                        </div>
                        <div className="flex items-center justify-center p-10 font-semibold text-gray-500 grid grid-cols-1 sm:grid-cols-4 gap-y-4">
                          {reportsStat?.districts.map(district => {
                            return <h5 className="mx-4 text-sm">{district}</h5>
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="width-home2 h-full hidden xl:block relative pl-20">
                    <div className="slider">
                      <NewImageSlider
                        partner={partner}
                        slides={
                          partner && partner.sliders ? partner.sliders : sliders
                        }
                        plugins={["arrows", "infinite"]}
                      ></NewImageSlider>
                    </div>
                  </div>
                )
              ) : (
                <div className="width-home2 h-full hidden xl:block relative pl-20">
                  <div className="slider">
                    <NewImageSlider
                      partner={partner}
                      slides={
                        partner && partner.sliders ? partner.sliders : sliders
                      }
                      plugins={["arrows", "infinite"]}
                    ></NewImageSlider>
                  </div>
                </div>
              )}
            </div>
          </div>
          {partner && partner.partner_logos.length !== 0 && (
            <div
              className={`flex items-start flex-col lg:flex-row pb-14 content mx-auto px-10 lg:px-0`}
            >
              <div className="w-full lg:w-1/5 h-40 mt-8">
                <div
                  className="text-3xl blue-dark1 font-semibold my-6 "
                  // dangerouslySetInnerHTML={{ __html: SkillsTrainerPartners }}
                >
                  {/* <h1>Skills Trainer</h1>
              <p>Partners</p> */}
                  <p>
                    <MultiLangField name={"Partners1"}>
                      {partner ? partner?.slider_title : "SkillsTrainer"}
                    </MultiLangField>
                  </p>
                  <p>
                    <MultiLangField name={"Partners2"}>Partners</MultiLangField>
                  </p>
                </div>
                <div className="w-14 h-1 bg-red-dark"></div>
              </div>
              <div className="w-full lg:w-4/5">
                <div className="lg:mr-4 mt-3 flex items-center height-partner">
                  <Marquee speed={50}>
                    {partner
                      ? partner.partner_logos.map(i => {
                          return (
                            <img
                              src={i}
                              style={{ flex: "none" }}
                              className="mr-20 max-h-20"
                            />
                          )
                        })
                      : [
                          Partner17,
                          Partner18,
                          Partner20,
                          Partner2,
                          Partner4,
                          Partner6,
                          Partner7,
                          Partner8,
                          Partner9,
                          Partner10,
                          Partner11,
                          Partner12,
                          Partner13,
                          Partner14,
                          Partner15,
                          Partner16,
                          Partner21,
                          Partner1,
                          Partner3,
                          Partner5,
                        ].map(i => {
                          return (
                            <img
                              src={i}
                              style={{ flex: "none" }}
                              className="mr-20"
                            />
                          )
                        })}
                  </Marquee>
                </div>
                {/* <div className="flex items-center my-16 ml-20">
              <div className="bg-red-dark w-24 h-1.5"></div>
              <div className="bg-gray-500 w-24 h-0.5"></div>
            </div> */}
              </div>
            </div>
          )}

          {!partner && (
            <section className="journey w-full content sm:mx-auto ">
              <div className="text-center text-4xl font-semibold px-10 lg:px-0">
                {/* <p dangerouslySetInnerHTML={{ __html: YourJourney }}>
                 <span className="blue-dark1">Your Journey</span>
              <div className="mt-5">
                <span className="blue-dark1">For A</span>
                <span className="red-dark">{} Better Life..</span>
              </div> 
           



              </p> */}

                <span className="blue-dark1">
                  <MultiLangField name={"Journey_Title"}>
                    Your Journey
                  </MultiLangField>
                </span>
                {/* <div className="mt-5">
                <span className="blue-dark1">For A</span>
                <span className="red-dark">Better Life..</span>
              </div> */}
              </div>

              <div className="bg-blue-grad lg:mx-20 sm:px-10 mt-18 md:mt-44 pt-10 md:pt-96 lg:pt-36 pb-10 relative">
                {/* <div
                className="flex flex-wrap justify-center md:absolute md:-top-32 md:left-10 md:right-10 lg:shadow-lg divide-x"
                dangerouslySetInnerHTML={{ __html: fivePoints }}
              > */}
                <div className="flex flex-wrap justify-center md:absolute md:-top-32 md:left-0 md:right-0 lg:shadow-lg divide-x">
                  <div className="border-journey-1 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                    <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-blue-dark1 text-white text-center">
                      1
                    </div>
                    <p className="text text-md mt-8">
                      <MultiLangField name={"Journey_benefits1"}>
                        SkillsTrainer Course & Assessment
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="border-journey-2 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                    <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-green-light text-white text-center">
                      2
                    </div>
                    <p className="text text-md mt-8">
                      <MultiLangField name={"Journey_benefits2"}>
                        Govt. Approved/ Industry Validated Certification
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="border-journey-3 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                    <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-orange-light2 text-white text-center">
                      3
                    </div>
                    <p className="text text-md mt-8">
                      <MultiLangField name={"Journey_benefits3"}>
                        Job or Your Business
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="border-journey-4 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                    <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-orange-light1 text-white text-center">
                      4
                    </div>
                    <p className="text text-md mt-8">
                      <MultiLangField name={"Journey_benefits4"}>
                        Another SkillsTrainer course
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="border-journey-5 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                    <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-red-dark text-white text-center">
                      5
                    </div>
                    <p className="text text-md mt-8">
                      <MultiLangField name={"Journey_benefits5"}>
                        A Better Job or a Bigger Business
                      </MultiLangField>
                    </p>
                  </div>
                </div>

                <div className="flex flex-col xl:flex-row items-center justify-between">
                  <div className="flex flex-col lg:flex-row items-center mt-10 lg:mt-0 story-cards">
                    {stories.slice(0, 3).map(story => (
                      <StoryCard data={story} />
                    ))}
                  </div>
                  <div className="flex flex-col items-start xl:items-end justify-between my-16 xl:my-0">
                    <div></div>
                    {/* dangerouslySetInnerHTML={{ __html: ThisCouldBeYou }} */}
                    <p className="blue-dark2 text-4xl font-semibold xl:text-right">
                      <MultiLangField name={"Journey_aside1a"}>
                        This Could
                      </MultiLangField>{" "}
                      <br />
                      <MultiLangField name={"Journey_aside1b"}>
                        be you!
                      </MultiLangField>
                    </p>
                    <p className="text-md text xl:text-right my-16">
                      <Link to="/stories">
                        <MultiLangField name={"Journey_aside2a"}>
                          Click for many such Life
                        </MultiLangField>{" "}
                        <br />{" "}
                        <MultiLangField name={"Journey_aside2b"}>
                          Changing Stories
                        </MultiLangField>
                      </Link>
                    </p>
                    {/* data cmng from strapi in this div */}
                    <div>
                      <button className="bg-orange text-white font-semibold rounded-lg p-3 text-md">
                        <a href={webappHost} target="_blank">
                          <MultiLangField name={"Journey_button"}>
                            Join for Free
                          </MultiLangField>
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="Offers">
            {partner?.scholarships && (
              <div className="mb-10">
                <div
                  className={`${partner ? "mt-0" : "mt-32"} mb-8 px-10 lg:px-0`}
                >
                  <p className="text-4xl font-semibold text-japanese_indigo text-center">
                    Scholarships
                  </p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-1 md:gap-4 mx-auto px-2 md:px-40">
                  {partner.scholarships.slice(0, 9).map(item => (
                    <ScholarshipCard
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      description={item.description}
                      amount={item.amount}
                      social_categories={item.social_categories}
                      gender={item.gender}
                      min_age={item.min_age}
                      max_age={item.max_age}
                      logo={item.partner.logo}
                      organization_name={item.partner.organization_name}
                      created_at={item.created_at}
                      details={item}
                    />
                  ))}
                </div>
              </div>
            )}
            <div
              className={`${partner ? "mt-0" : "mt-32"} mb-10 px-10 lg:px-0`}
            >
              <p className="text-4xl font-semibold text-japanese_indigo text-center">
                <MultiLangField name={"topCourses_Title1"}>
                  {partner ? "" : "Top"} Courses & Special{" "}
                </MultiLangField>
                <span className="text-orange">
                  <MultiLangField name={"topCourses_Title2"}>
                    Offers
                  </MultiLangField>
                </span>
              </p>
            </div>
            {!courses && (
              <div className="w-full flex justify-center p-4">
                <Loader style={{ width: "130px" }} />
              </div>
            )}
            {courses && courses.length === 0 && (
              <div className="w-full flex justify-center p-4 text-2xl font-semibold text-gray-400">
                Couldn't fetch courses
              </div>
            )}
            {courses && courses.length > 0 && (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 md:gap-4 mx-auto px-2 md:px-40">
                  {courses.slice(0, 9).map(obj => (
                    <CourseCard data={obj} />
                  ))}
                </div>
                {courses.length > 9 && (
                  <div className="max-w-max mx-auto mt-14 mb-28">
                    <Link
                      to={
                        language == "en"
                          ? "/courses"
                          : "/" + language + "/courses"
                      }
                    >
                      <button className="bg-japanese_indigo-light text-japanese_indigo font-semibold px-10 py-2 text-base rounded-lg">
                        <MultiLangField name={"topCourses_button"}>
                          View All
                        </MultiLangField>
                      </button>
                    </Link>
                  </div>
                )}
              </>
            )}
          </section>
          {!partner && (
            <section className="career">
              <div className="bg-blue-grad relative py-12 lg:mx-16">
                <div
                  className="text-center text-4xl font-semibold mb-10 blue-dark2 px-10"
                  // dangerouslySetInnerHTML={{ __html: ExploreOtherCourses }}
                >
                  {/* <p>
                <span className="blue-dark1">
                  Explore All Other Courses Across 12+
                  <br /> Sectors For An
                </span>
                <span className="red-dark">{} Attractive Career</span>
              </p> */}
                  <p>
                    <span className="blue-dark1">
                      <MultiLangField name={"sectors_Title1"}>
                        Explore All Other Courses Across 12+
                      </MultiLangField>
                      <br />
                      <MultiLangField name={"sectors_Title2a"}>
                        Sectors For An{" "}
                      </MultiLangField>
                    </span>
                    <span className="red-dark">
                      <MultiLangField name={"sectors_Title2b"}>
                        Attractive Career
                      </MultiLangField>
                    </span>
                  </p>
                </div>
                <div className="flex flex-wrap items-center justify-center pt-4 content mx-auto">
                  {coursesByCategories
                    ?.slice(0, !active ? 12 : coursesByCategories.length)
                    .map((obj, catIndex) => {
                      let image =
                        (typeof obj.image === "string" && obj.image) ||
                        (obj.image &&
                          ((obj.image.formats &&
                            obj.image.formats.medium &&
                            obj.image.formats.medium.url) ||
                            obj.image.url))
                      return (
                        obj.categoryName && (
                          <Link to={"/courses/" + obj.id}>
                            <div className="relative m-3">
                              {((image || catIndex === 0) && (
                                <img
                                  src={
                                    catIndex
                                      ? image
                                      : require("../assets/image/all-category.png")
                                          .default
                                  }
                                  className="height-career"
                                />
                              )) || (
                                <div
                                  className="height-career"
                                  style={{ background: "#444" }}
                                />
                              )}
                              <div className="career-option text-white bg-gray-800 bg-opacity-50 absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">
                                <p className="text-xl font-semibold text-center">
                                  {obj.categoryName}
                                </p>
                                <p className="text-md mt-2">
                                  {obj.numOfCourses
                                    ? `${obj.numOfCourses}+`
                                    : obj.numOfCourses}{" "}
                                  courses
                                </p>
                                <button className="btn-white">
                                  View Sector
                                </button>
                              </div>
                            </div>
                          </Link>
                        )
                      )
                    })}
                </div>

                <div className="max-w-max mx-auto my-16">
                  <button
                    className="bg-japanese_indigo-light text-sm text-japanese_indigo font-semibold p-4 rounded-lg"
                    onClick={() => setActive(!active)}
                  >
                    {!active ? (
                      <MultiLangField name={"sectors_buttonAll"}>
                        Browse All Sectors
                      </MultiLangField>
                    ) : (
                      <MultiLangField name={"sectors_buttonFew"}>
                        Show Less
                      </MultiLangField>
                    )}
                  </button>
                </div>
              </div>
            </section>
          )}
          {!partner && (
            <section className="mx-auto stacked-linear py-12 my-20 content">
              <div className="flex flex-col lg:flex-row items-center lg:justify-evenly">
                <div className="mx-10 w-110">
                  <div className="p-2 w-full rounded-3xl relative bg-white">
                    <img src={Career} className="rounded-lg w-full" />
                  </div>
                  <div className="flex flex-col sm:flex-row items-center justify-between my-4 mb-2 text-sm">
                    <div className="bg-white rounded-xl font-semibold px-3 py-3 w-full sm:mr-2">
                      <MultiLangField name={"courseNameSWAR"}>
                        Spoken English Program
                      </MultiLangField>
                    </div>
                    <div className="bg-white rounded-xl px-3 py-1 sm:text-center w-full mt-4 sm:mt-0">
                      <p className="blue-dark2">
                        <MultiLangField name={"courseType"}>
                          English speaking course
                        </MultiLangField>
                      </p>
                      <p className="text">34,455 Students</p>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row items-center justify-between">
                    <div className="py-3 px-2 bg-white rounded-xl flex items-center w-full sm:w-auto">
                      <p className="mx-2 text-sm">
                        <MultiLangField name={"swar_time"}>
                          3 months, 36 one Hour Live Sessions
                        </MultiLangField>
                      </p>
                    </div>
                    <div className="py-3 px-3 bg-white rounded-xl flex items-center w-full sm:w-auto sm:mt-0">
                      <p className="mx-2 text-sm">
                        <span className="red-dark">NSQF </span>{" "}
                        <span className="text">
                          <MultiLangField name={"level"}>Level</MultiLangField>{" "}
                          2
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between my-3 bg-white p-3 rounded-xl">
                    <div className="flex items-center bg-orange text-white font-semibold rounded-lg p-3">
                      {/* <img src={Cart} className="h-8 w-8" /> */}
                      <a href={webappHost} target="_blank">
                        <button className="text-sm md:text-md px-5 font-semibold">
                          <MultiLangField name={"getEnrolled"}>
                            Get Enrolled
                          </MultiLangField>
                        </button>
                      </a>
                    </div>
                    <div className="flex items-center">
                      <div className="flex items-center mr-2 relative max-w-max">
                        <div className="bg-green-600 h-1 w-20 absolute top-1/2 left-2 transform -rotate-12"></div>
                        <img src={RuppeeGray} className="h-4 w-4" />
                        <p className="text-sm text-gray-400 font-semibold">
                          <MultiLangField name={"swar_discount_price"}>
                            1200/month
                          </MultiLangField>
                        </p>
                      </div>

                      <div className="flex items-center">
                        <img src={Ruppee} className="h-6 w-6" />
                        <p className="text-lg green-dark font-semibold">
                          <MultiLangField name={"swar_actuall_price"}>
                            900/month
                          </MultiLangField>
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="my-1 w-full primary-button"
                    onClick={() =>
                      setPath("/course-details/" + englishStarCourseId)
                    }
                  >
                    See more details
                  </button>
                </div>
                <div className="mt-14 lg:mt-0 text-2xl text-gray-500 mx-10 sm:w-4/5 lg:w-2/5 shadow-2xl bg-white rounded-3xl line-text">
                  {/* <div
                  className="bg-white rounded-3xl divide-y divide-gray-400 w-96 shadow-2xl "
                  dangerouslySetInnerHTML={{ __html: LiveSessionAvailable }}
                >
                </div> */}
                  <img
                    className="rounded-t-xl"
                    src={EnglishStarLogo}
                    alt="English-star-logo"
                  />
                  <p className="text-orange text-2xl md:text-3xl font-semibold my-2 px-6">
                    <MultiLangField name={"swar_learn"}>
                      Learn Spoken English
                    </MultiLangField>
                  </p>
                  <p className="text-orange text-2xl md:text-3xl font-semibold my-2 px-6">
                    in 3 Months & 36 Hrs
                  </p>
                  <p className="border-t border-b p-4 text-base mt-3">
                    <MultiLangField name={"swar_Knowledge"}>
                      Speak, Write, Acknowledge & Read English
                    </MultiLangField>
                  </p>
                  <p className="p-4 text-base">
                    <MultiLangField name={"swar_description"}>
                      36 Live 1 hour Training Sessions With Ceritified Spoken
                      English Experts from Delhi, Mumbai, Bangalore, Kolkata
                      Etc.
                    </MultiLangField>
                  </p>
                  <p className="border-t border-b p-4 text-base">
                    <MultiLangField name={"swar_benefit"}>
                      Unlimited Access to 100+ Hrs Digital Content For 1 Year
                    </MultiLangField>
                  </p>
                  <p className="p-4 text-base">
                    <MultiLangField name={"swar_map"}>
                      Course mapped to Common European Framework of Reference
                      (CEFR) and English for Employability, NSQF Level 2 of
                      Govt. of India
                    </MultiLangField>
                  </p>
                </div>
              </div>
            </section>
          )}
        </div>
      </Layout>
    </MultiLangBody>
    // </TinaProvider>
  )
}

const sortEnglishCourse = courses => {
  // Sorting english course
  const englishCourse = courses.find(e => e.courseId === englishStarCourseId)

  if (englishCourse) {
    const index = courses.indexOf(englishCourse)
    courses.splice(index, 1)
    courses.splice(0, 0, englishCourse)
  }

  return courses
}

export default IndexPage
